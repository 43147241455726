import React from "react";
import { Link } from "theme-ui";
import SVG from "react-inlinesvg";
import gatsbySVG from "../../assets/Gatsby-Logo.svg";

const styles = {
  link: {
    display: `inline-flex`,
    color: `heading`,
    fontWeight: `medium`,
    ":visited": {
      color: `heading`,
    },
    svg: {
      fill: `omegaDarker`,
      height: 24,
      ml: 2,
    },
  },
};

const PoweredByGatsby = () => (
  <p>
    Made with ❤️ from France using the{" "}
    <a href="https://www.gatsbyjs.com/"> Gatsby </a> framework and deploying on{" "}
    <a href="https://www.netlify.com/"> Netlify</a>{" "}
  </p>
);
export default PoweredByGatsby;
